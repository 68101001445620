import React, { createContext, useState, useContext } from 'react';

const ThreadContext = createContext(null);

export const useThread = () => useContext(ThreadContext);

export const ThreadProvider = ({ children }) => {
  const [threadId, setThreadId] = useState('');

  const updateThreadId = (newThreadId) => {
    setThreadId(newThreadId);
  };

  return (
    <ThreadContext.Provider value={{ threadId, updateThreadId }}>
      {children}
    </ThreadContext.Provider>
  );
};


// import React, { useState, useEffect } from 'react';

// function SajuPage() {
//   const [assistantId, setAssistantId] = useState('draw_base_saju');
//   const [threadId, setThreadId] = useState('');
//   const [threadMap, setThreadMap] = useState(new Map());

//   useEffect(() => {
//     // When assistantId changes, get the threadId for the new assistantId
//     // or set it to an empty string if it doesn't exist.
//     setThreadId(threadMap.get(assistantId) || '');
//   }, [assistantId, threadMap]);

//   const handleAssistantIdChange = (newAssistantId) => {
//     // Store the current threadId for the current assistantId before switching
//     setThreadMap((prevMap) => {
//       const newMap = new Map(prevMap);
//       newMap.set(assistantId, threadId);
//       return newMap;
//     });

//     // Now switch to the new assistantId
//     setAssistantId(newAssistantId);
//   };

//   const handleThreadIdChange = (newThreadId) => {
//     setThreadId(newThreadId);

//     // Update the threadMap with the new threadId for the current assistantId
//     setThreadMap((prevMap) => {
//       const newMap = new Map(prevMap);
//       newMap.set(assistantId, newThreadId);
//       return newMap;
//     });
//   };

//   return (
//     <div>
//       <h1>Saju Page</h1>
//       <div>
//         <label>Assistant ID: </label>
//         <select value={assistantId} onChange={(e) => handleAssistantIdChange(e.target.value)}>
//           <option value="draw_base_saju">draw_base_saju</option>
//           <option value="assistant_2">assistant_2</option>
//           <option value="assistant_3">assistant_3</option>
//           {/* Add more options as needed */}
//         </select>
//       </div>
//       <div>
//         <label>Thread ID: </label>
//         <input
//           type="text"
//           value={threadId}
//           onChange={(e) => handleThreadIdChange(e.target.value)}
//         />
//       </div>
//     </div>
//   );
// }

// export default SajuPage;
