import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThreadProvider } from './ThreadContext';
import HomePage from './pages/HomePage';
import SajuPage from './pages/SajuPage'; // SajuPage 컴포넌트를 추가합니다.
import './App.css';

function App() {
  return (
    <div className="App">
      <ThreadProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/saju" element={<SajuPage />} /> {/* /saju 경로 추가 */}
            {/* 필요한 다른 경로도 여기에 추가하세요 */}
          </Routes>
        </Router>
      </ThreadProvider>
    </div>
  );
}

export default App;
