import React, { useState, useEffect, useMemo, useCallback, useRef} from 'react';
import ChatBot from 'react-simple-chatbot';
// import { useThread } from '../ThreadContext';
import '../App.css';
import botAvatar from '../male_avatar_bot_profile-min.png'
import { ThemeProvider } from "styled-components"



//TODO: 시간 setState해서 세션이 끝나기 전까지 유지해 줄 수 있도록 한다.

const csrfToken = window.csrfToken;

// const ohangColors = {
//   "화": "#FF2C4C", // Light Salmon (연한 Red)
//   "수": "#71A8FF", // Light Sky Blue (연한 Blue)
//   "목": "#00DE5D", // Light Green (연한 Green)
//   "금": "#FFE448", // Moccasin (연한 Gold)
//   "토": "#886F68", // Tan (연한 Brown)
// };

const ohangData = {
  "목": { color: "#00DE5D", symbol: "🌳" },
  "화": { color: "#FF2C4C", symbol: "🔥" },
  "토": { color: "#886F68", symbol: "🏔️" },
  "금": { color: "#FFE448", symbol: "🪙" },
  "수": { color: "#71A8FF", symbol: "💧" }
};


const YesNoButtons = ({ triggerNextStep, time_info }) => {
  const [buttonsVisible, setButtonsVisible] = useState(true);

  const handleResponse = (response) => {

    setButtonsVisible(false);

    const triggerData  = {
      trigger: 'display-interactive-component',
      value: {
        which_interative_component: 'display_user_message',
        time_info : time_info,
        message: response
      },
    };

    // 사용자의 응답을 다음 스텝에 전달
    triggerNextStep(triggerData);
  };

  return (
    <div className="interactive-container">
      {buttonsVisible && (
        <div className="button-group">
          <button className="yes-button" onClick={() => handleResponse('응')}>
            응
          </button>
          <button className="no-button" onClick={() => handleResponse('아니')}>
            아니
          </button>
        </div>
      )}
    </div>
  );
};



const UserMessage  = ({ triggerNextStep, time_info, message }) => {

    if( message ==='응' ){
      console.log("CHECKING USER MESSAGE:", message)
      //여기서 넘어가기 전에 
      const triggerData  = {
        trigger: 'user-input-processor',
        value: {
          time_info : time_info,
        }
      }
      // 사용자의 응답을 다음 스텝에 전달
      triggerNextStep(triggerData);
    } 

    return (
      <div className="user-message-container">
        <div className="user-message">
          {message}
        </div>
      </div>
    );
};


function SajuPage() {
  const [assistantId, setAssistantId] = useState('draw_base_saju');
  const [threadId, setThreadId] = useState('');
  const [threadMap, setThreadMap] = useState(new Map());


  const [birthday, setBirthday] = useState({
      year: 1990,
      month: 12,
      day: 12,
      hour: 12,
      minute: 12
  });

  const birthdayRef = useRef(birthday)

  useEffect(()=>{
    birthdayRef.current = birthday
    console.log("Birthday REF Set!", birthdayRef.current )
  },[birthday])

  // Refs to store the latest threadId and assistantId
  const threadIdRef = useRef(threadId);
  const assistantIdRef = useRef(assistantId);

  useEffect(() => {
    threadIdRef.current = threadId;
  }, [threadId]);

  useEffect(() => {
    assistantIdRef.current = assistantId;
  }, [assistantId]);

  const handleAssistantIdChange = (newAssistantId) => {
    setThreadMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(assistantId, threadIdRef.current); // Use ref to ensure the latest threadId
      return newMap;
    });

    setAssistantId(newAssistantId);
    setThreadId(threadMap.get(newAssistantId) || '');
  };

  const handleThreadIdChange = (newThreadId) => {
    setThreadId(newThreadId);
    setThreadMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(assistantIdRef.current, newThreadId); // Use ref to ensure the latest assistantId
      return newMap;
    });
  };

  const changeAssistantAndThreadId = async (newAssistantId, newThreadId) => {
    // 상태 업데이트는 비동기이기 때문에 그 이후 작업을 처리하기 위해 Promise를 사용
    await new Promise((resolve) => {
      setThreadMap((prevMap) => {
        const newMap = new Map(prevMap);
        newMap.set(assistantId, threadIdRef.current);
        return newMap;
      });

      // 상태 업데이트 후 resolve 호출
      setAssistantId(newAssistantId);
      handleThreadIdChange(newThreadId);
      resolve();
    });

    // Promise가 resolve된 후에 다음 작업이 진행된다
    console.log("AssistantId after update (async):", assistantIdRef.current);
    console.log("ThreadId after update (async):", threadIdRef.current);
  };

  useEffect(() => {
    changeAssistantAndThreadId('draw_base_saju', '');
    console.log('first update:', threadId, assistantId);
  }, []);

  useEffect(() => {
    console.log('threadId Updated:', threadId, assistantId);
  }, [threadId]);


  function UserInputProcessor({ previousStep, triggerNextStep }) {
    const [response, setResponse] = useState('입력중');
    const [loadingDots, setLoadingDots] = useState('.');
    const [isLoading, setIsLoading] = useState(true);
    const [delayMessage, setDelayMessage] = useState(null);

    useEffect(() => {
      // 상위 범위에서 loadingInterval과 delayCheck를 선언하여 모든 함수에서 접근 가능하게 함
      let loadingInterval;
      let delayCheck;
    
      const fetchData = async () => {
        loadingInterval = setInterval(() => {
          if (isLoading) {
            setLoadingDots((prev) => (prev === '...' ? '.' : prev + '.'));
          }
        }, 700);
    
        delayCheck = setTimeout(() => {
          if (isLoading) {
            setDelayMessage('조금만 기다려줘🙏');
          }
        }, 15000);
    
        // 경우에 따라서 사용하는 GPT 어시스턴트를 스위치 해줄수 있어야 한다.
        // 현재 사용하고 있는 어시스턴트 및 스레드아이디를 체크한다(새로 스레드를 파는것 방지 - 이전대화 기억)
        // Use refs to access the latest threadId and assistantId
        let currentThreadId = threadIdRef.current;
        let currentAssistantId = assistantIdRef.current;
        let currentBirthday = birthdayRef.current;
    
        console.log("UserInputProcessorCheck:", previousStep.value);
    
        let body_params = JSON.stringify({ 
          message: previousStep.value, 
          thread_id: currentThreadId, 
          assistant_id_key: currentAssistantId
        });

        if (previousStep.value?.action === 'paid-contents') {
          // await를 사용하여 changeAssistantAndThreadId 함수의 완료를 기다립니다.
          await changeAssistantAndThreadId('get_more_base_saju', '');
                
          // 상태 업데이트를 기다리는 함수
          const waitForStateUpdate = () => new Promise(resolve => {
            const checkState = () => {
              if (assistantIdRef.current === 'get_more_base_saju') {
                resolve();
              } else {
                setTimeout(checkState, 50);
              }
            };
            checkState();
          });
          
          await waitForStateUpdate();
          
          body_params = JSON.stringify({ 
            message: birthdayRef.current, 
            thread_id: threadIdRef.current, 
            assistant_id_key: assistantIdRef.current
          });
        }
    
        console.log("Fetch Start:", assistantIdRef.current);
    
        try {
          const response = await fetch('https://www.tagmeet.io/saju/api/chatbot/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
            },
            body: body_params,
          });
          const data = await response.json();
    
          clearInterval(loadingInterval);
          clearTimeout(delayCheck);
          setIsLoading(false);
          setResponse(data.message);
    
          // Handle thread ID update
          if ('thread_id' in data) {
            console.log('threadid update:', threadIdRef.current, assistantIdRef.current);
            console.log('threadid update value:', data.thread_id);
            handleThreadIdChange(data.thread_id); // This will update the state correctly
            console.log('Saju Data:', data.saju_data);
          }
    
          if ('draw-base-saju' === data.next_step) {
            setBirthday(data.saju_data.time_info);
          }
    
          // views.py <-----> Inputprocessor가 핵심임
          // 결과에 따라 어떤 다음 액션을 보여줄지
          const triggerData = {
            trigger: data.next_step,
            value: {
              baseSaju: data.saju_data || {},
            },
          };
          triggerNextStep(triggerData);

        } catch (error) {
          clearInterval(loadingInterval);
          clearTimeout(delayCheck);
          setIsLoading(false);
          setResponse('Failed to get response. Try again later.');
          triggerNextStep({ trigger: 'error' });
        }
      };
    
      fetchData();
    
      return () => {
        clearInterval(loadingInterval);
        clearTimeout(delayCheck);
      };
    }, [previousStep, triggerNextStep]);

    // useEffect(() => {
    //   const loadingInterval = setInterval(() => {
    //     if (isLoading) {
    //       setLoadingDots((prev) => (prev === '...' ? '.' : prev + '.'));
    //     }
    //   }, 1000);

    //   const delayCheck = setTimeout(() => {
    //     if (isLoading) {
    //       setDelayMessage('조금만 기다려주세요🙏');
    //     }
    //   }, 15000);

    //   // 경우에 따라서 사용하는 GPT 어시스턴트를 스위치 해줄수 있어야 한다.
    //   // 현재 사용하고 있는 어시스턴트 및 스레드아이디를 체크한다(새로 스레드를 파는것 방지 - 이전대화 기억)
    //   // Use refs to access the latest threadId and assistantId
    //   let currentThreadId = threadIdRef.current;
    //   let currentAssistantId = assistantIdRef.current;


    //   console.log("UserInputProcessorCheck:", previousStep.value)

    //   let body_params = JSON.stringify({ message: previousStep.value, thread_id: currentThreadId, assistant_id_key: currentAssistantId})

    //   if (previousStep.value?.action){

    //     if(previousStep.value?.action==='paid-contents'){
    //       changeAssistantAndThreadId('get_more_base_saju', '')
    //       body_params = JSON.stringify({ message: birthday, thread_id: '' , assistant_id_key: assistantIdRef.current})
    //     }
    //   }


    //   console.log("Fetch Start:", assistantIdRef.current)  
      

    //   fetch('https://www.tagmeet.io/saju/api/chatbot/', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'X-CSRFToken': csrfToken,
    //     },
    //     body: body_params
    //   })
    //     .then((response) => response.json())
    //     .then((data) => {
    //       clearInterval(loadingInterval);
    //       clearTimeout(delayCheck);
    //       setIsLoading(false);
    //       setResponse(data.message);

    //       // Handle thread ID update
    //       if ('thread_id' in data) {
    //         console.log('threadid update:', threadIdRef.current, assistantIdRef.current);
    //         console.log('threadid update value:', data.thread_id);
    //         handleThreadIdChange(data.thread_id); // This will update the state correctly

    //         console.log('Saju Data:', data.saju_data)
    //       }
          
    //       if ('draw-base-saju' ==  data.next_step){
    //         setBirthday(data.saju_data.time_info)
    //       }
    //       // views.py <-----> Inputprocessor가 핵심임
    //       // 여기가 분기점으로 포커스 포인트
    //       // TODO:DEV
    //       // 결과에 따라 어떤 다음 액션을 보여줄지
    //       //TODO:views.py에서 나온 data.next_step 을 이용해서 다음 노드를 연결시켜주면 될듯하다
    //       const triggerData = {
    //         trigger: data.next_step,
    //         value: {
    //           baseSaju: data.saju_data || {},
    //         },
    //       };

    //       triggerNextStep(triggerData);
    //     })
    //     .catch(() => {
    //       clearInterval(loadingInterval);
    //       clearTimeout(delayCheck);
    //       setIsLoading(false);
    //       setResponse('Failed to get response. Try again later.');
    //       triggerNextStep({ trigger: 'error' });
    //     });

    //   return () => {
    //     clearInterval(loadingInterval);
    //     clearTimeout(delayCheck);
    //   };
    // }, [previousStep, triggerNextStep]);

    const displayResponse = isLoading
      ? delayMessage
        ? `${delayMessage}${loadingDots}`
        : `입력중${loadingDots}`
      : response;

    return displayResponse;
  }




 //유료 사주 운세 4,5,6 - 재물, 연애
 function GetMoreBaseSaju({ steps, previousStep, triggerNextStep }) {
  const {
    time_info,
    income_potential,
    investment_potential,
    expenditure_habit,
    dating_style,
    ideal_partner,
    breakup_reason,
  } = previousStep.value.baseSaju;

  const triggerData = {
    trigger: 'terminate-service',
  };

  useEffect(() => {
    triggerNextStep(triggerData); // Move to the next step
  }, [triggerNextStep]);

  // Function to format text by replacing newline characters and slashes
  const formatText = (text) => {
    return text
      .replace(/\\n/g, '<br><br>')  // Replace \n with <br/> for new line
      .replace(/\//g, '<br><br>');   // Replace / with <br/> for new line
  };

  // Function to render bullet points from text with dots or new lines
  const renderBulletPoints = (text) => {
    return text.split(/・|\\n|\/|\./g).map((line, index) => (
      line.trim() && <li key={index}>{line.trim()}</li>
    ));
  };

  const renderListItems = (text) => {
    return text.split(/\\n|・/g).map((item, index) => (
      <li key={index}>{item.trim()}</li>
    ));
  };

  const renderDatingStyleSubData = (category, subData) => {
    if (subData && subData.contents) {
      return (
        <div className="info-item" key={category}>
          <strong>{category}:</strong>
          <p>{subData.contents}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="base-saju-info">
      {/* 소득 가능성 카드 */}
      <div className="card">
        <h3 className="card-title">소득 가능성</h3>
        <div className="card-content">
          <div className="info-item">
            <strong>소득 경쟁력:</strong>
            <ul>{renderBulletPoints(income_potential.contents.level.contents)}</ul>
          </div>
          <div className="info-item">
            <strong>소득 기회와 타이밍:</strong>
            <ul>{renderBulletPoints(income_potential.contents.will.contents)}</ul>
          </div>
        </div>
      </div>

      {/* 투자 가능성 카드 */}
      <div className="card">
        <h3 className="card-title">투자 가능성</h3>
        <div className="card-content">
          <div className="info-item">
            <strong>투자 성향:</strong>
            <p>{investment_potential.type}</p>
          </div>
          <div className="info-item">
            <strong>투자 관련 주요 특징:</strong>
            <ul>{renderBulletPoints(investment_potential.keyword)}</ul>
          </div>
        </div>
      </div>

      {/* 소비 습관 카드 */}
      <div className="card">
        <h3 className="card-title">소비 습관</h3>
        <div className="card-content">
          <div className="info-item">
            <strong>소비 스타일:</strong>
            <p>{expenditure_habit.title}</p>
          </div>
          <div className="info-item">
            <strong>지출 경향:</strong>
            <ul>{renderBulletPoints(expenditure_habit.contents)}</ul>
          </div>
        </div>
      </div>

      {/* 연애 및 결혼 성향 카드 */}
      <div className="card">
        <h3 className="card-title">연애 및 결혼 성향</h3>
        <div className="card-content">
          {renderDatingStyleSubData('독립적 성향', dating_style.data.dokrip)}
          {renderDatingStyleSubData('비독립 성향', dating_style.data.bedokrip)}
          {renderDatingStyleSubData('근왕 성향', dating_style.data.geunwang)}
          {renderDatingStyleSubData('인성 성향', dating_style.data.insung)}
          {renderDatingStyleSubData('삼합 성향', dating_style.data.samhap)}
          {renderDatingStyleSubData('토간 성향', dating_style.data.togan)}
        </div>
      </div>

      {/* 만나면 좋은 이성 카드 */}
      <div className="card">
        <h3 className="card-title">이상적인 이성</h3>
        <div className="card-content">
          <div className="info-item">
            <strong>이성의 특징:</strong>
            <p>{ideal_partner.title}</p>
          </div>
          <div className="info-item">
            <strong>상세 설명:</strong>
            <ul>{renderBulletPoints(ideal_partner.contents)}</ul>
          </div>
        </div>
      </div>

      {/* 이별의 이유 카드 */}
      <div className="card">
        <h3 className="card-title">이별의 이유</h3>
        <div className="card-content">
          <div className="info-item">
            <strong>이별을 부르는 행동:</strong>
            <p>{breakup_reason.title}</p>
          </div>
          <div className="info-item">
            <strong>세부 내용:</strong>
            <ul>{renderBulletPoints(breakup_reason.contents)}</ul>
          </div>
        </div>
      </div>
    </div>
  );
}
    


  //무료 사주 운세 1,2,3 - 성격, 재능, 충합관련 내용
  function BaseSajuInfo({ steps, previousStep, triggerNextStep }) {
    const { saju_pillar, umyangohang, time_info, personality, talent, talent_type } = previousStep.value.baseSaju;

    let which_character = '';
    let anger_trigger = '';

    if (personality && personality.data.contents) {
      const parts = personality.data.contents.split('/');
      which_character = parts[0] || '';  // First item after split
      anger_trigger = parts[1] || '';    // Second item after split
    }

    const [isTriggered, setIsTriggered] = useState(false);

     // useCallback을 사용하여 triggerNextStep 호출 최적화
    const handleTriggerNextStep = useCallback(() => {
      if (!isTriggered) {
        triggerNextStep({
          trigger: 'ask-user-manually',
          value: {
            time_info: time_info,
            message: "재물운 그리고 연애운도 궁금하지??"
          }
        });
        setIsTriggered(true);
      }
    }, [isTriggered, time_info, triggerNextStep]);

    useEffect(() => {
      handleTriggerNextStep();
    }, [handleTriggerNextStep]);
  
    // Function to format text by replacing newline characters and slashes
    const formatText = (text) => {
      return text
        .replace(/\\n/g, '<br><br>')  // Replace \n with <br/> for new line
        .replace(/\//g, '<br><br>');   // Replace / with <br/> for new line
    };
  
    const renderKeywords = (keywords) => {
      return keywords
        .replace(/\//g, '') // Remove slashes for keyword tags
        .split('#')          // Split by '#'
        .filter(Boolean)     // Remove empty strings
        .map((keyword, index) => (
          <span key={index} className="keyword-badge">
            #{keyword.trim()}
          </span>
        ));
    };

    const renderBulletPoints = (text) => {
      return text.split(/\/|\\n/g).map((line, index) => (
        line.trim() && <li key={index}>{line.trim()}</li>
      ));
    };
  
    return (
      <div className="base-saju-info">
        <h2 className="title">사주 풀이</h2>
  
        {/* Personality Section */}
        {personality && (
          <div className="card">
            <h3 className="card-title">타고난 성격</h3>
            <div className="card-content">
              <div className="info-item">
                <strong>일간:</strong>
                <p>{personality.data.ilgan}</p>
              </div>
              <div className="info-item">
                <strong>한마디로 하면:</strong>
                <p>{personality.data.ilganDesciption}</p>
              </div>
              <div className="info-item">
                <strong>키워드:</strong>
                {renderKeywords(personality.data.subtitle)}
              </div>
              <div className="info-item">
                <strong>어떤사람?:</strong>
                <ul>
                  {renderBulletPoints(which_character)}
                </ul>
              </div>
              <div className="info-item">
                <strong>화나게 하는 것:</strong>
                <ul>
                  {renderBulletPoints(anger_trigger)}
                </ul>
              </div>
            </div>
          </div>
        )}
  
        {/* Talent Section */}
        {talent && (
          <div className="card">
            <h3 className="card-title">타고난 재능</h3>
            <div className="card-content">
              <div className="info-item">
                <strong>용신:</strong>
                <p>{talent.youngsin}</p>
              </div>
              <div className="info-item">
                <strong>한마디로 하면:</strong>
                <p>{talent.data.subtitle}</p>
              </div>
              <div className="info-item">
                <strong>키워드:</strong>
                {renderKeywords(talent.data.tag)}
              </div>
              <div className="info-item">
                <strong>어떤재능?:</strong>
                <p dangerouslySetInnerHTML={{ __html: formatText(talent.data.contents) }} />
              </div>
            </div>
          </div>
        )}
  
        {/* Talent Type Section */}
        {talent_type && (
          <div className="card">
            <h3 className="card-title">인재 유형</h3>
            <div className="card-content">
              <div className="info-item">
                <strong>분야:</strong>
                <p>{talent_type.title}</p>
              </div>
              <div className="info-item">
                <strong>인재유형:</strong>
                <p>{talent_type.type}</p>
              </div>
              <div className="info-item">
                <strong>설명하자면?:</strong>
                <p dangerouslySetInnerHTML={{ __html: formatText(talent_type.contents) }} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  
  // 사주팔자 기본 8글자
  function DrawBaseSaju({ steps, previousStep, triggerNextStep }) {
    // Accessing the data passed from the previous step
    // const baseSaju = previousStep.value.baseSaju;
    const { saju_pillar, umyangohang, personality, talent, talent_type } = previousStep.value.baseSaju;
    const [hasTriggered, setHasTriggered] = useState(false); // 중복 방지 상태

    //단계 : draw-base-saju (그림 사주팔자) ---> base-saju-info (기본 사주팔자 내용) 단 무료내용인 재능/적성까지.
    const triggerData = {
      trigger: 'base-saju-info',
      value: {
        baseSaju: previousStep.value.baseSaju
      },
    };
    
    useEffect(() => {
      if (!hasTriggered) {
        triggerNextStep(triggerData); // Move to the next step
        setHasTriggered(true); // 한 번만 실행되도록 설정
      }
    }, [hasTriggered, triggerNextStep]);

    return (
      <div className="saju-container">
      <h2 className="saju-title">사주명식</h2>
      <div className="saju-pillar-grid">
        {/* Headers */}
        {/* <div className="pillar-header">시주</div>
        <div className="pillar-header">일주</div>
        <div className="pillar-header">월주</div>
        <div className="pillar-header">년주</div> */}

        {/* 천간 and 지지 Row */}
        <OhangBox 
          sky={umyangohang.timeSky.ohang} 
          land={umyangohang.timeLand.ohang} 
          pillar={{ sky: saju_pillar.timeSky, land: saju_pillar.timeLand }} 
          headerSky="시간"
          headerLand="시지"
          headerSkyExtraText=" 󠀠"
          headerLandExtraText=" 󠀠"
        />
        <OhangBox 
          sky={umyangohang.daySky.ohang} 
          land={umyangohang.dayLand.ohang} 
          pillar={{ sky: saju_pillar.daySky, land: saju_pillar.dayLand }} 
          headerSky="일간"
          headerLand="일지"
          headerSkyExtraText="나"
          headerLandExtraText=" 󠀠"
        />
        <OhangBox 
          sky={umyangohang.monthSky.ohang} 
          land={umyangohang.monthLand.ohang} 
          pillar={{ sky: saju_pillar.monthSky, land: saju_pillar.monthLand }} 
          headerSky="월간"
          headerLand="월지"
          headerSkyExtraText=" 󠀠"
          headerLandExtraText="사회"
        />
        <OhangBox 
          sky={umyangohang.yearSky.ohang} 
          land={umyangohang.yearLand.ohang} 
          pillar={{ sky: saju_pillar.yearSky, land: saju_pillar.yearLand }} 
          headerSky="년간"
          headerLand="년지"
          headerSkyExtraText=" 󠀠"
          headerLandExtraText=" 󠀠"
        />
      </div>
    </div>
    );
  }


   
  function AskUserManually({ steps, previousStep, triggerNextStep }) {

    // Accessing the data passed from the previous step
    // const baseSaju = previousStep.value.baseSaju;
    const time_info = previousStep.value.time_info;
    const message = previousStep.value.message;

    const [hasTriggered, setHasTriggered] = useState(false);
    
    console.log("Ask User Manually Data Check:", time_info)

   
    useEffect(() => {
    if (!hasTriggered) {

      // triggerNextStep({
      //   trigger: 'display-interactive-component',
      //   value: {
      //     which_interative_component: 'yes_or_no_check_for_paid_saju_info',
      //     time_info: time_info,
      //   },
      // });
      triggerNextStep({
        trigger: 'display-yes-or-no',
        value: {
          time_info: time_info,
        },
      });
      setHasTriggered(true); // 한 번만 실행되도록 상태 변경

    }
  }, [hasTriggered, triggerNextStep, time_info]);

    return message
  }


     
  function DisplayInteractiveComponent({ steps, previousStep, triggerNextStep }) {
    const which_interative_component = previousStep.value.which_interative_component;
    const time_info = previousStep.value.time_info;

    const message =  previousStep.value.message || '' //optional value

    if(which_interative_component =='display_user_message'){
      
      // 재물운과 연애운을 더 보겠다는 의미이므로 어시스턴트 변경하자
      if(message=='응'){
        changeAssistantAndThreadId('get_more_base_saju', '')
      }
      //TODO: 지금은 메시지 기준으로 어시스턴트를 변경하지만..향후엔 다른 변수도 같이 봐야 할듯

    }
  
    console.log("DisplayInteractiveComponent Data Check:", which_interative_component);
  
    const renderComponentByType = () => {
      switch (which_interative_component) {
        case 'yes_or_no_check_for_paid_saju_info':
          return <YesNoButtons triggerNextStep={triggerNextStep} time_info={time_info} />;

        case 'display_user_message':
          return <UserMessage  triggerNextStep={triggerNextStep} time_info={time_info} message={message} />;

        // Add more cases for other interactive components
        default:
          return <p>올바른 컴포넌트를 찾을 수 없습니다.</p>;
      }
    };
  
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}> 
        {renderComponentByType()} {/* Call the appropriate render function based on the type */}
      </div>
    );
  }



  // TODL: DEV
  //현재 플로우

  // 무료 플로우
  // start --> 
  // user-input --> user-ipnut-processor - views.py에서 판단하고 넥스트 스텝을 정해서 보여준다.
  // 내용에 따라 draw-base-saju --> base-saju-info
  // base-sajuinfo --> user-input으로 돌아옴

  // 유료 플로우
  //유료 컨텐르를 보시겠습니까? (라는 메시지를 주어야 하기 때문에)
  // base-saju-info가 끝나고 메시지를 어떻게 display 할것인지?


  //챗봇 플로우 노드 구성
  const [steps, setSteps] = useState([
    {
      id: 'start',
      message: '안녕?! 생년월일 그리고 태어난 시간을 오전, 오후 구분하여 입력해줄래?',
      trigger: 'user-input',
    },


    ////////////////////////////////////////////////////////////////////////////////
    // 유저가 입력하면 ----> 유저 인풋 프로세서로 들어가게 된다.
    // UserInputProcessor는 user-input에서 받은 내용을 서버에 보내서 가져온다.
    {
      id: 'user-input',
      user: true,
      trigger: 'user-input-processor',
    },
    // 중개기 노드라고 보면 될듯한데..?
    
    {
      id: 'user-input-processor',
      component: <UserInputProcessor/>,
      waitAction: true,
      trigger: 'user-input',
      asMessage: true
    },


    {
      id: 'display-yes-or-no',
      options: [
        { 
          label: '응', 
          trigger: 'user-input-processor',
          value: {
            action: 'paid-contents', // 이전 스텝의 time_info를 다음 스텝으로 전달
          }
        },
        { 
          value: "아니", 
          label: '아니', 
          trigger:'terminate-service'
        },
      ],
    },


    ////////////////////////////////////////////////////////////////////////
    {
      id: 'draw-base-saju',
      component: <DrawBaseSaju />, //기본 8개의 사주팔자
      waitAction: true,
      trigger: 'user-input'
    },
    {
      id: 'base-saju-info',
      component: <BaseSajuInfo />, // 적성/ 재능
      waitAction: true,
      trigger: 'user-input',
    },

    // 유료 컨텐츠 
    {
      id: 'get-more-base-saju',
      component: <GetMoreBaseSaju/>, // 재물, 연애
      waitAction: true,
      trigger: 'user-input',
    },

    ////////////////////////////////////////////////////////////////////////
    // 서버에 보내지 않고 컨텐츠 분기점을 만들어주는 노드
    // 경우에 따라서 assistant_id_key 값을 변경해줘야 할수도 있다. 
    {
      id: 'ask-user-manually', // ex) 예시 연애운도 보시겠습니까?
      component: <AskUserManually/>, // 적성/ 재능
      waitAction: true,
      trigger: 'display-interactive-component',
      asMessage: true
    },
    {
      id: 'display-interactive-component', // ex) 예시 연애운도 보시겠습니까?
      component: <DisplayInteractiveComponent/>, // 적성/ 재능
      waitAction: true,
      trigger: 'error', // 버튼 같은 interactive component의 대답에 따라 nextStep넘어갈떄 데이터를 넘겨준다.
    },

    // 메시지를 디스플레이 해주고, 유적가 '네', or '아니로'를 선택할 수 있게 해줘야 한다.
    //여기서 나온 답변 UI 형태로 고를 수 있게 해줘야 할듯한데
    //tirgger가 user-input이 아니라, 어떤 컴포넌츠여야 할 것이고..

    // 그 컴포넌트를 클릭했을때, user-input이 아니라 user-input-processor로 보내줘야 할 것이다.
    // 보낼 때는 생년월일시의 정보를 함께 보내줘야 할듯허다 
    // 그말인 즉슨 이미 이 서비스를 이용한 시점에서 생년월일 정보가 나올때
    // 메모리에 저장해둘 수 있어야 한다는 말이다.
    {
      id: 'error',
      message: 'There was an error processing your request.',
      end: true,
    }, 
    {
      id: 'terminate-service',
      message: '사주풀이가 완료 되었어 :)',
      end: true,
    }
  ]);

  const botName = "서화도령"

  const theme = {
    background: "#f5f8fb",
    fontFamily: "Helvetica Neue",
    headerBgColor: "#007BFF",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#007BFF",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  }

  return <div className="saju-page">
     <div className="chatbot"> 
      <ThemeProvider theme={theme}>
        <ChatBot
        style={{height:'calc(100vh)'}}
        contentStyle={{ 
          height: 'calc(100vh - 120px)',
          }}
          customStyle={{
            padding: '0',  // 부모 컨테이너의 여백 제거
            margin: '0',   // 부모 컨테이너의 마진 제거
            marginBottom:'10px',
            backgroundColor: 'transparent', // 필요에 따라 배경색 설정
            boxShadow: 'none'
          }}
        botAvatar={botAvatar}
        hideUserAvatar ={true}
        headerTitle={botName}
        steps={steps} />
      </ThemeProvider>
    </div>
  </div>;

}


const OhangBox = ({ sky, land, pillar, headerSky, headerLand, headerSkyExtraText, headerLandExtraText }) => {
  return (
    <div className="pillar">
      <div className="pillar-section">
        <div className="pillar-header">{headerSky}</div>
        <div
          className="pillar-sky"
          style={{ backgroundColor: ohangData[sky].color }}
        >
          <span className="ohang-extra-text">{headerSkyExtraText}</span>
          <span className="ohang-symbol">{ohangData[sky].symbol}</span>
          <span className="ohang-text">{pillar.sky}</span>
        </div>
      </div>
      <div className="pillar-section">
        <div className="pillar-header">{headerLand}</div>
        <div
          className="pillar-land"
          style={{ backgroundColor: ohangData[land].color }}
        >
          <span className="ohang-extra-text">{headerLandExtraText}</span>
          <span className="ohang-symbol">{ohangData[land].symbol}</span>
          <span className="ohang-text">{pillar.land}</span>
        </div>
      </div>
    </div>
  );
};



export default SajuPage;
