import React, { useState, useEffect } from 'react';
import ChatBot from 'react-simple-chatbot';
import { useThread } from '../ThreadContext';
import '../App.css';
import botAvatar from '../bot_profile.jpg'
import { ThemeProvider } from "styled-components"

function HomePage() {
  const { threadId, updateThreadId } = useThread();

  const [steps, setSteps] = useState([
    {
      id: 'start',
      message: '안녕하세요.맞춤 영양제를 추천해드리고자 합니다. 신체상 불편한 부분이나, 원하는 건강 상태가 있으신가요?',
      trigger: 'name-input',
    },
    {
      id: 'name-input',
      user: true,
      trigger: 'submit-name',
    },
    {
      id: 'submit-name',
      component: <SubmitName />,
      waitAction: true,
      trigger: 'user-input',
      asMessage: true
    },
    {
      id: 'special-offer-step',
      component: <SpecialOfferComponent />,
      waitAction: true,
      trigger: 'user-input'
    },
    {
      id: 'user-input',
      user: true,
      trigger: 'submit-name',
    },
    {
      id: 'error',
      message: 'There was an error processing your request.',
      end: true,
    }
  ]);

  const botName = "영양제 상담가 Jarvis"

  const theme = {
    background: "#f5f8fb",
    fontFamily: "Helvetica Neue",
    headerBgColor: "#007BFF",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#007BFF",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  }

  return <div className="home-page">
     <div className="chatbot"> 
    <ThemeProvider theme={theme}>
      <ChatBot
       style={{height:'calc(100vh)'}}
       contentStyle={{ 
        height: 'calc(100vh - 120px)',
        }}
       botAvatar={botAvatar}
       headerTitle={botName}
       steps={steps} />
    </ThemeProvider>
    </div>
  </div>;
}

function SpecialOfferComponent({ steps, previousStep, triggerNextStep }) {
  // Accessing the data passed from the previous step
  const specialOffers = previousStep.value.specialOffer;
  const message = previousStep.value.message;

  // You can use the data in your component logic or render method
  useEffect(() => {
    // You can trigger next step or set state based on the special offer details here
  }, [specialOffers]);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
      {specialOffers.map((offer, index) => (

        <div key={index} className="card-container">
          <div className="card-image" aria-label="이미지">
            <img src={`/vitamin/image-proxy/?url=${encodeURIComponent(offer.product_image_url)}`} alt={offer.product_name} />
          </div>
          <div className="card-content">
            <div className="card-item" aria-label="상품명">
              <h3>{offer.product_name}</h3>
            </div>
            <div className="card-item" aria-label="비타민 - 미션명">
              <p>{offer.vitamin_name} - {offer.vitamin_benefit}</p>
            </div>
            <div className="card-item" aria-label="가격">
              <p>{offer.product_price} 원</p>
            </div>
          </div>
          <div className="card-footer" aria-label="제품 보기">
            <a href={offer.product_url} target="_blank" rel="noopener noreferrer">
              제품 보기
            </a>
          </div>
        </div>

        
      ))}
    </div>
  );
}


// function SubmitName({ previousStep, triggerNextStep }) {
//   const [response, setResponse] = useState('입력중...');
//   const { threadId, updateThreadId } = useThread();

//   useEffect(() => {
//     // console.log("Using Thread ID:", threadId);

//     fetch('vitamin/api/chatbot/', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ message: previousStep.value, thread_id: threadId })
//     })
//     .then(response => response.json())
//     .then(data => {
//       setResponse(data.message);

//       if ('thread_id' in data) {
//         updateThreadId(data.thread_id);
//         // console.log('THREAD_ID Updated:', data.thread_id);
//       }

//       if (data.next_step === 'special-offer') {
//         setTimeout(() => {
//           triggerNextStep({ 
//             trigger:'special-offer-step',
//             value: {
//               specialOffer: data.product_info,
//               message: 'test haha mesage' // Any additional data you want to pass
//             }
//           });
//         }, 100);
//       }else {
//         setTimeout(() => {
//           triggerNextStep({ trigger: 'user-input' });
//         }, 100);
//       }


//     })
//     .catch(() => {
//       setResponse('Failed to get response. Try again later.');
//       triggerNextStep({ trigger: 'error' });
//     });

//   }, [previousStep, triggerNextStep]);

//   return response;
// }


function SubmitName({ previousStep, triggerNextStep }) {
  const [response, setResponse] = useState('입력중'); // Default loading message
  const [loadingDots, setLoadingDots] = useState('.'); // Loading animation
  const [isLoading, setIsLoading] = useState(true); // Indicates if still loading
  const [delayMessage, setDelayMessage] = useState(null); // Holds the optional delay message
  const { threadId, updateThreadId } = useThread();

  useEffect(() => {
    const loadingInterval = setInterval(() => {
      if (isLoading) {
        setLoadingDots((prev) => {
          // Cycle through '.', '..', '...', then back to '.'
          return prev === '...' ? '.' : prev + '.';
        });
      }
    }, 1000); // Update every second

    // Check if loading has exceeded 15 seconds
    const delayCheck = setTimeout(() => {
      if (isLoading) {
        setDelayMessage('영양제를 검색하는 단계로 조금만 기다려주세요🙏'); // Set delay message
      }
    }, 15000); // 15 seconds delay check

    // Fetch response from the server
    fetch('vitamin/api/chatbot/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: previousStep.value, thread_id: threadId }),
    })
      .then((response) => response.json())
      .then((data) => {
        clearInterval(loadingInterval); // Clear interval once response is received
        clearTimeout(delayCheck); // Clear timeout when response is received
        setIsLoading(false); // No longer loading
        setResponse(data.message); // Set final response

        // Handle thread ID update
        if ('thread_id' in data) {
          updateThreadId(data.thread_id);
        }

        // Trigger the next step based on server response
        const triggerData = {
          trigger: data.next_step === 'special-offer' ? 'special-offer-step' : 'user-input',
          value: {
            specialOffer: data.product_info || [],
          },
        };

        triggerNextStep(triggerData); // Proceed to the next step
      })
      .catch(() => {
        clearInterval(loadingInterval); // Clear the interval on error
        clearTimeout(delayCheck); // Clear delay check on error
        setIsLoading(false); // No longer loading
        setResponse('Failed to get response. Try again later.');
        triggerNextStep({ trigger: 'error' });
      });

    // Cleanup interval and timeout on component unmount
    return () => {
      clearInterval(loadingInterval);
      clearTimeout(delayCheck);
    };
  }, [previousStep, triggerNextStep]);

  // Build the response message based on loading and delay status
  const displayResponse = isLoading
    ? delayMessage // Use delay message if set
      ? `${delayMessage}${loadingDots}` // Append loading dots to delay message
      : `입력중${loadingDots}` // Default loading message with dots
    : response; // Final response after loading

  return displayResponse; // Return the final display message
}



export default HomePage;
